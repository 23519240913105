<template>
  <div class="access">
  <div class="access2">

    <div class="inner">
    <img class="parking" src="../assets/gotemba_truck.webp" alt="勝間田タイヤ">
    </div>
    
    <p class="access_pkk">
      静岡県御殿場市、国道138号沿いの主に乗用車・トラック専門のタイヤショップです。
    </p>
    <div class="innerkk">
      <img class="exterior_class_kk" src="../assets/kk50th.png" loading="lazy">
    </div>
    <p class="access_p">
      創業50年目、お店と作業ピットは2016年にリニューアルしています。
    </p>

    <h2 class="heading">法人のお客様へ</h2>
    <img class="parking" src="../assets/textmessagetruck.png" alt="法人のお客様へ">
    <p class="access_p">
      会社のトラックや営業車などのタイヤ交換もお任せください。<br>
      安心安全のためにタイヤ交換だけでなく、点検やナットの増し締め作業も可能です。<br>
      タイヤをできるだけ長く使えるようにアドバイスも行っています。
    </p>

    <h2 class="heading">定期点検</h2>
    <img class="parking" src="../assets/forclient.webp" alt="定期点検">
    <p class="access_p">
      タイヤを長く使うためには定期点検が大事です。<br>
      空気圧点検やタイヤ溝の減り具合によってタイヤの位置替え（ローテーション）が必要な場合があります。
    </p>

    <h2 class="heading">ブリヂストンタイヤ</h2>
    <img class="parking" src="../assets/bslogo2.png" alt="ブリヂストンタイヤ">
    <p class="access_p">
      勝間田タイヤでは、安心安全のブリヂストンタイヤを取り付けさせていただきます。<br>
      商用車にも国内ブランドの性能と品質が高いタイヤがおすすめです。<br>
      ブリヂストンタイヤは低燃費やロングライフでコスト削減に貢献します。<br>
      国内シェアNo.1のブリヂストンタイヤなら、お仕事のクルマでも長期的に安心して運転できます。
    </p>

    <h2 class="heading">様々な車両に対応</h2>
    <div class="inner">
      <img class="parking" src="../assets/ablelist.webp" alt="ニーズに対応">
    </div>
    <p class="access_p">
      乗用車・商用バンの他に、高速路線トラック・地域トラック・トラクタ・セミトレーラー・観光バス・路線バス・ダンプ・小型トラック・フォークリフトなど、多様な車両に対応します。
    </p>

    <h2 class="heading">リトレッドタイヤ</h2>
    <img class="parking" src="../assets/retreadbs.webp" alt="リトレッドタイヤ">
    <p class="access_p">
      リドレッドタイヤとは、トラックやバス用タイヤの古いトレッドゴムを新しく張り替えたタイヤです。<br>
      台タイヤを再利用できるので、新品タイヤを購入するよりトータルコストが削減できます。<br><br>
      ブリヂストンのトラックタイヤは高い台耐久性があり、ベースとなる台タイヤが審査に通ればリトレッドタイヤとして再利用ができます。<br><br>
      <v-btn href="https://www.bs-brm.jp/about/retread-tire/">詳しく→</v-btn>
    </p>

    <h2 class="heading">リトレッドタイヤ使用例</h2>
    <div class="inner">
      <img class="parking" src="../assets/retread_install.webp" alt="リトレッドタイヤ装着">
    </div>
    <p class="access_p">
      リトレッドタイヤは全ての車輪で使うわけではなく、新品タイヤ＋リトレッドタイヤで揃えます。<br>
      リトレッドタイヤは後輪に装着するのが一般的です。<br>
      車両によって推奨装着方法が異なりますので、お気軽にご相談ください。<br><br>
      新品タイヤは使用後も状態が良ければ、次回タイヤ交換時にリトレッドタイヤとして再利用できます。
    </p>

    
    <h2 class="heading">作業について</h2>
    <div class="inner">
    <img class="parking" src="../assets/tsa.webp" alt="作業について">
    </div>
    <p class="access_p">
      勝間田タイヤでは事故防止のために、ブリヂストンの定める認定基準を満たし確実なタイヤ装着を行なっております。<br>
      タイヤ販売だけでなくスペアタイヤ交換、内面からのパンク修理やバスなど大人数を乗せる車両にはタイヤのバランス調整も可能です。
    </p>

    <h2 class="heading">ナットの増し締め</h2>
    <div class="inner">
    <img class="parking" src="../assets/Retighten.webp" alt="事故防止について">
    </div>
    <p class="access_p">

      タイヤ装着後のナットの増し締め作業も可能です。有料のサービスにはなりますが、安全安心のために定期的に実施する法人のお客様が増えています。

    </p>

    <h2 class="heading">作業ピット</h2>
    <div class="inner">
    <img class="parking" src="../assets/pit1.webp" alt="タイヤ交換ピット">
    <img class="parking" src="../assets/pit2.webp" alt="タイヤ交換ピット">
    <img class="parking" src="../assets/pit3.webp" alt="タイヤ交換ピット">
    </div>
    <p class="access_p">
      大型タイヤチェンジャー<br>
      大型バランサー<br>
      乗車用リフト<br>
      乗用車チェンジャー<br>
      乗用車バランサー
    </p>


    <h2 class="heading">乗用車の入口</h2>
    <div class="inner">
    <img class="parking" src="../assets/carparking1.webp" alt="乗用車駐車場1">
    <img class="parking" src="../assets/carparking2.webp" alt="乗用車駐車場2">
    </div>
    <p class="access_p">
    乗用車の入口は箱根側にあります。<br>
    個人のお客様や普通車でお越しの方はこちらの駐車場をご利用ください。
    </p>

    <h2 class="heading">トラックの入口</h2>
    <div class="inner">
    <img class="parking" src="../assets/parking_big.webp" alt="アクセス">
    <img class="parking" src="../assets/bigtruckp.webp" alt="大型車両">
    <img class="parking" src="../assets/parking3.webp" alt="トラック作業場">
    </div>
    <p class="access_p">
    大型トラック・ダンプの広めの入口は富士山側にあります。<br>
    国道138号沿いで、どちらの方面からもスムーズに入れます。
    </p>

    <h2 class="heading">お気軽にどうぞ</h2>
    <div class="inner">
    <img class="parking" src="../assets/callme.webp" alt="お問い合わせ">
    </div>
    <p class="access_p">
    夏・冬の履き替えや、タイヤの在庫、パンク修理など、乗用車・トラックタイヤのことは勝間田タイヤにお気軽にお問い合わせください！<br><br>
    TEL : <a href="tel:0550-89-1219">0550-89-1219</a>
    </p>

    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3255.5320046656275!2d138.91672521539522!3d35.31760838028073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6019771c2aa02be9%3A0xb5bb682c17c99296!2z44Of44K544K_44O844K_44Kk44Ok44Oe44OzIOWvjOWjq-WxseW-oeauv-WgtOW6lyDli53plpPnlLDjgr_jgqTjg6Q!5e0!3m2!1sja!2sjp!4v1663991824298!5m2!1sja!2sjp" width=100% height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <p class="about_p">ACCESS</p>
    <h1>勝間田タイヤ</h1>
    <p>静岡県御殿場市茱萸沢241-1</p>
    <a class="acc_a" href="https://goo.gl/maps/68HfK1CfSBGu4P1P9">Google Maps</a>
  </div>
  <div>
  <Footer/>
  </div>
  </div>

</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'Home',
  components: {
    Footer
  }
}
</script>

<style scoped>
.access{
  padding-top: 0rem;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
}
.access2{
  padding-top: 0rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 6rem;
}
.access h1{
  margin-top: 0.8rem;
  padding-bottom: 0.5rem;
  text-align:left;
  font-size: 1.9rem;
  letter-spacing: 0.12rem;
}
.access p{
  text-align:left;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.7rem;
  letter-spacing: 0.06rem;
}
.access_p{
  padding-bottom:7rem;
}
.access_pkk{
  padding-bottom:0rem;
}

.acc_a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 160px;
  height: 50px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  background-color: #9099a2;
  border-radius: 40px;
  margin-top: 30px;
}
.acc_a::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #d5d5d5;
  border-right: 2px solid #d5d5d5;
  transform: rotate(45deg);
}

.access h2{
  padding-top: 0.3rem;
  font-size: 1.5rem;
}

.about_p{
  padding-top: 7.3rem;
}

nav {
  margin-top: -7rem;
  padding-bottom: 6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.8rem;
  text-align:left;
  line-height: 1.6rem;
}

nav a {
  font-weight: bold;
  text-decoration: none;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
nav h3{
  font-size: 1.5rem;
}



@media screen and ( max-width:767px )
{
  .access img {
    width: 100%;
    padding-bottom: 2rem;
    padding-top: 1rem;
  }
  .parking_full {
	display: block;
	width: 100%;
	height: auto;
  }
  .inner {
	margin-right: calc(50% - 50vw);
	margin-left: calc(50% - 50vw);
  }
  .innerkk{
    width: 70%;
    margin: 0 auto;
  }
}
@media screen and ( min-width:768px )
{
  .access img {
    width: 50%;
    padding-bottom: 2rem;
    padding-top: 1rem;
    padding-right: 2rem;
    margin:0 auto;
  }
  .parking_full {
	display: block;
	width: 100%;
	height: auto;
  }
  .inner {
  }
  .innerkk{
    width: 40%;
    margin: 0 auto;
  }
}

.heading {
   padding: 0 0em 20px;
   letter-spacing: 0.07rem;
   font-size: 26px;
   background-image: repeating-linear-gradient( /*線形グラデーション*/
      -45deg, /*斜線にするために-45°回転させる*/
      #92C7CF 0px, #92C7CF 2px, /*線の色と幅を指定*/
      rgba(0 0 0 / 0) 0%, rgba(0 0 0 / 0) 50% /*線間の余白指定*/
   );
   background-size: 8px 8px; /*線形グラデーションを指定したbackgroundのサイズ*/
   background-repeat: repeat-x; /*背景の繰り返し指定*/
   background-position: center bottom;
}



.parking_full_cite {
	display: block;
  text-align: center;
	height: auto;
  margin-top: -2rem;
  padding-bottom: 2rem;
}
.first_h2{
  margin-top: 1rem;
  padding-bottom: 3rem;
  line-height: 2.5rem;
  letter-spacing:0.1rem;
}

</style>
