<template>
  <div class="access">
  <div class="access2">

    <h2 class="heading">卸販売について</h2>
    <img class="parking" src="../assets/bslogo2.png" alt="法人のお客様へ">
    <p class="access_p">
      勝間田タイヤでは、カーディーラーや中古車販売店、整備工場にタイヤの卸販売を行なっております。<br>
      ブリヂストンタイヤの販売と組み替え作業が可能です。<br>
      詳しくはお電話か店頭にてご説明いたします。
    </p>

   

    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3255.5320046656275!2d138.91672521539522!3d35.31760838028073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6019771c2aa02be9%3A0xb5bb682c17c99296!2z44Of44K544K_44O844K_44Kk44Ok44Oe44OzIOWvjOWjq-WxseW-oeauv-WgtOW6lyDli53plpPnlLDjgr_jgqTjg6Q!5e0!3m2!1sja!2sjp!4v1663991824298!5m2!1sja!2sjp" width=100% height="300" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <p class="about_p">ACCESS</p>
    <h1>勝間田タイヤ</h1>
    <p>静岡県御殿場市茱萸沢241-1</p>
    <a class="acc_a" href="https://goo.gl/maps/68HfK1CfSBGu4P1P9">Google Maps</a>
  </div>
  <div>
  <Footer/>
  </div>
  </div>

</template>

<script>
import Footer from '@/components/Footer.vue'
export default {
  name: 'Home',
  components: {
    Footer
  }
}
</script>

<style scoped>
.access{
  padding-top: 0rem;
  padding-left: 0rem;
  padding-right: 0rem;
  padding-bottom: 0rem;
}
.access2{
  padding-top: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 6rem;
}
.access h1{
  margin-top: 0.8rem;
  padding-bottom: 0.5rem;
  text-align:left;
  font-size: 1.9rem;
  letter-spacing: 0.15rem;
}
.access p{
  text-align:left;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.7rem;
  letter-spacing: 0.06rem;
}
.access_p{
  padding-bottom:6rem;
}

.acc_a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 160px;
  height: 50px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  background-color: #9099a2;
  border-radius: 40px;
  margin-top: 30px;
}
.acc_a::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #d5d5d5;
  border-right: 2px solid #d5d5d5;
  transform: rotate(45deg);
}

.access h2{
  padding-top: 0.3rem;
  font-size: 1.5rem;
}

.about_p{
  padding-top: 7.3rem;
}

nav {
  margin-top: -7rem;
  padding-bottom: 6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: 0.8rem;
  text-align:left;
  line-height: 1.6rem;
}

nav a {
  font-weight: bold;
  text-decoration: none;
  color: #2c3e50;
}
nav a.router-link-exact-active {
  color: #42b983;
}
nav h3{
  font-size: 1.5rem;
}



.access img {
  width: 100%;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

.heading {
   padding: 0 0em 20px;
   letter-spacing: 0.07rem;
   font-size: 26px;
   background-image: repeating-linear-gradient( /*線形グラデーション*/
      -45deg, /*斜線にするために-45°回転させる*/
      #92C7CF 0px, #92C7CF 2px, /*線の色と幅を指定*/
      rgba(0 0 0 / 0) 0%, rgba(0 0 0 / 0) 50% /*線間の余白指定*/
   );
   background-size: 8px 8px; /*線形グラデーションを指定したbackgroundのサイズ*/
   background-repeat: repeat-x; /*背景の繰り返し指定*/
   background-position: center bottom;
}

.inner {
	margin-right: calc(50% - 50vw);
	margin-left: calc(50% - 50vw);
}
.parking_full {
	display: block;
	width: 100%;
	height: auto;
}
.parking_full_cite {
	display: block;
  text-align: center;
	height: auto;
  margin-top: -2rem;
  padding-bottom: 2rem;
}
</style>
